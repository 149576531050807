.projects-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.projects-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 80em;  
}

h2 {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 30px;
}

.project-card {
  position: relative;
  margin-bottom: 30px;
  border-radius: 8px;
  overflow: hidden;
  width: 70%;
  height: 180px;
  display: flex;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: rgba(0, 0, 0, 0.1) 1px solid;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add transition */
  background-color: rgba(241, 241, 241, 0.202);
}

.project-card:hover {
  transform: scale(1.03); /* Slightly scale the card */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Make shadow more prominent */
}

.project-card::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid transparent;
  transition: border-color 0.3s ease;
}

.project-card:hover::after {
  border-color: rgba(255, 255, 255, 0.6); /* Add a border color on hover */
}

/* img container */
.project-image {
  width: 200px;
  height: 100%;
  order: 2;
}

.project-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project-info {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  padding: 15px;
  overflow: hidden;
}

.project-heading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: transform 0.3s ease;
  padding: 15px;
  z-index: 2; /* Ensure header is on top */
}

.project-description {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: transform 0.3s ease;
  padding: 15px;
  z-index: 1;
}

.project-heading {
  transform: translateY(0%);
}

.project-description {
  transform: translateY(-100%);
}

.project-card:hover .project-heading {
  transform: translateY(100%);
}

.project-card:hover .project-description {
  transform: translateY(0%);
}

.project-media {
  width: 300px;
  height: 100%;
  order: 2;
  overflow: hidden;
}

.learn-more {
  transform: translateY(-2vh);
  font-style: italic;
  font-weight: bold;
  color: #ffffff; 
}

.project-media img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project-media video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.project-info h2 {
  margin: 0 0 20px;
  justify-content: center;
}

.project-info h3 {
  margin-bottom: 5px;
  font-size: 1.2em;
  color: white;
}

.project-info p {
  max-width: 95%;
}

.project-description {
  font-size: 0.9em;
  line-height: 1.4;
  color: white;
}

@media (max-width: 900px) {
  .projects-grid {
    width: 100%;
  }

  .project-card {
    width: 100%;
    flex-direction: column;
    height: auto;
    padding-top: 5px; /* Add padding to the top of the card */
  }

  .project-media {
    width: 100%;
    height: 200px;
    order: 2;
    margin-top: 10px; 
  }

  .project-info {
    order: 1;
    display: block; 
  }

  .project-heading {
    position: static;
    display: block; 
    transform: none;
    padding: 0;
  }

  .project-info h2 {
    font-size: 1.2rem;
    text-align: center; 
  }

  
  .project-description,
  .project-info h3,
  .project-info p,
  .learn-more {
    display: none;
  }
}