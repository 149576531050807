body.modal-open {
  overflow: hidden;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  animation: fadeIn 0.3s forwards;
  color: whitesmoke;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.modal-content {
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  height: 90%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
  overflow-y: auto;
  transform: translateY(-30px);
  opacity: 0;
  transition: transform 0.3s, opacity 0.3s;
}

.modal-overlay.open .modal-content {
  transform: translateY(0);
  opacity: 1;
}

.modal-overlay.close {
  animation: fadeOut 0.3s forwards;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 2;
}

.modal-inner-content {
  display: flex;
  height: 100%;
}

.modal-text {
  flex: 1;
  padding-right: 20px;
  overflow-y: auto;
}

.modal-media {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.modal-media img,
.modal-media video {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

@media (max-width: 768px) {
  .modal-inner-content {
    flex-direction: column;
  }

  .modal-text, .modal-media {
    flex: none;
    width: 100%;
  }

  .modal-text {
    padding-right: 0;
    padding-bottom: 20px;
  }
}