.landing {
    position: relative;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  #gradient-canvas {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: opacity 0.2s ease-in-out;
  }
  
  .content {
    position: relative;
    z-index: 2;
    color: white;
    padding: 20px;
    text-align: center;
    padding-top: 0vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
  }

  .header {
    top: 0;
    left: 0;
    right: 0;
    padding: 10px 20px;
    z-index: 1000;
    text-align: center;
  }
  
  h1 {
    font-size: 3rem;
    width: 100%;
    font-weight: 1000;
  }
  
  a {
    color: white;
    text-decoration: none;
    border: 2px solid white;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  a:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .intro-box {
    background-color: rgba(26, 83, 25, 0.26);
    padding: 10px;
    margin-bottom: 1rem;
    width: 100%;
    border-radius: 10px;
    max-width: 810px;
    overflow: hidden;
    margin-top: 3vh;
  }
  
  .intro-box p {
    font-size: 1.1rem;
    line-height: 1.6;
    justify-content: center;
  }
  
  .social-links {
    margin-top: 2rem;
  }
  
  .social-links a {
    color: white;
    font-size: 2rem;
    margin: 0 10px;
    transition: color 0.3s ease;
    border: none;
    background: none;
  }
  
  .social-links a:hover {
    color: rgba(255, 255, 255, 0.379); 
  }
  
  .cta-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-bottom: 5px;
  }

  .cta-button, .cta-button:visited {
    display: inline-block;
    padding: 10px 20px;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1.1rem;
    transition: background-color 0.3s ease;
    background-color: transparent;
    border: 2px solid white;
    cursor: pointer;
    width: 120px; 
}
  
  .cta-button:hover {
    background-color: rgba(255, 255, 255, 0.379); 
  }

  .landing.modal-open {
    overflow: hidden;
  }
  
  .content.hidden {
    display: none;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    padding: 20px;
    border-radius: 5px;
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
    position: relative;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: white;  
    z-index: 1001;  
  }

  @media (max-width: 768px) {
    .landing {
      height: 100vh;
      overflow-y: auto;
    }
  
    .content {
      padding-top: 50px;
      overflow-y: visible;
    }
  
    h1 {
      font-size: 2rem;
      margin-bottom: 0px;
    }
  
    .intro-box {
      padding: 10px;
      margin-top: 0px;
    }
  
    .intro-box p {
      font-size: 0.9rem;
    }
  
    .cta-button, .cta-button:visited {
      padding: 8px 16px;
      font-size: 0.9rem;
    }
  
    .social-links a {
      font-size: 1.5rem;
      padding-bottom: 20px;
    }
  }